import React, { useContext } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  StylesProvider as MuiStylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  ThemeContext,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";

import { useFontObserver } from "../lib/helpers";

const breakpointValues = {
  xs: 0,
  xs1: 360,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
  xll: 2000,
};

const theme = {
  colors: {
    primary: "#001226",
    secondary: "#f6610c",
    accent: "#0F0F0F",
    background: "#FFF",
    text: "#001226",
    textLight: "#66717d",
    surface: "#e4edf2",
    surfaceDark: "#262626",
    textSurfaceDark: "#FFF",
    textSurfaceDisabled: "#d8d8d8",
    surfaceInputBackgroundDisabled: "#ececec",
    surfaceSecondary: "#fafafb",
    textSurfaceSecondary: "#343434",
    textSecondary: "#FFF",
    separatorSurfaceSecondary: "#ececec",
    link: "#0366db",
    linkDark: "#334151",
    linkDarkSurface: "#66717d",
    shadow: "#17001938",
    placeholder: "#b2b2b2",
    separator: "#ccd0d4",
    green: "#32a852",
    feedBackNegative: "#C60024",
    feedBackPositive: "#34BE34",
  },
  fonts: {
    primary: {
      regular: "Nunito Sans",
    },
    secondary: {},
  },
  headings: {
    h1: {
      fontSize: "32px",
      lineHeight: "34px",
      fontWeight: "700",
    },
    h2: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: "400",
    },
    h3: {
      fontSize: "20px",
      lineHeight: "26px",
      fontWeight: "400",
    },
    h4: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "600",
    },
    h5: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "700",
    },
    h6: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "700",
    },
  },
  paragraph: {
    mobile: {
      large: {
        fontSize: "20px",
        lineHeight: "26px",
      },
      medium: {
        fontSize: "16px",
        lineHeight: "18px",
      },
      small: {
        fontSize: "12px",
        lineHeight: "19px",
      },
      default: {
        fontSize: "14px",
        lineHeight: "16px",
      },
    },
    desktop: {
      large: {
        fontSize: "21px",
        lineHeight: "27px",
      },
      medium: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      small: {
        fontSize: "12px",
        lineHeight: "19px",
      },
      default: {
        fontSize: "15px",
        lineHeight: "20px",
      },
    },
  },
  links: {
    small: {
      fontSize: "12px",
      lineHeight: "15px",
    },
    default: {
      fontSize: "15px",
      lineHeight: "18px",
    },
  },
  navHeight: "60px",
};

const muiTheme = createMuiTheme({
  /* shadows: ['none'], */
  typography: {
    fontFamily: theme.fonts.primary.regular,
    fontSize: 12,
    htmlFontSize: 16,
  },
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.secondary,
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        height: "6px",
        borderRadius: "3px",
        border: "solid 1px #ccd0d4;",
        backgroundColor: "transparent !important",
      },
      bar1Determinate: {
        borderRadius: "3px",
        backgroundColor: theme.colors.secondary,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.colors.secondary,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.colors.secondary,
      },
      current: {
        color: theme.colors.secondary,
      },
    },
  },
  breakpoints: { values: breakpointValues },
});

theme.mui = muiTheme;

/**
 *  ThemeProvider
 */

export const ThemeProvider = ({ children }) => {
  const { fontsLoaded } = useFontObserver();
  const transparent = "transparent";
  const actualTheme = fontsLoaded
    ? theme
    : {
        ...theme,
        colors: {
          ...theme.colors,
          text: transparent,
          textLight: transparent,
          link: transparent,
          linkDark: transparent,
        },
      };

  return (
    <MuiStylesProvider injectFirst>
      <link
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <MuiThemeProvider theme={muiTheme}>
        <StyledThemeProvider theme={actualTheme}>
          {children}
        </StyledThemeProvider>
      </MuiThemeProvider>
    </MuiStylesProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

ThemeProvider.defaultProps = {
  children: null,
};

/**
 * Hooks
 */

export const useTheme = () => useContext(ThemeContext);

export default theme;
