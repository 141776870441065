import React from 'react'
import styled from 'styled-components'

import Nav from './components/Nav'
import { Hidden } from '@material-ui/core'

function App() {
  return (
    <Root>
      <Nav sticky={true} />
      <Main>
        <ContentWrapper>
          <ContentImageHero
            desktop="./images/banner-desktop.jpg"
            mobile="./images/banner-mobile.jpg"
          >
            <AboutSectionWrapper>
              <P>
                <Strong>HOXTON CONSULTING</Strong> is an international
                consultancy firm focused on providing investors and
                entrepreneurs world-class advice and services in construction
                administration, real estate investments, corporate formation and
                services, and business valuations.
              </P>
            </AboutSectionWrapper>
          </ContentImageHero>
          <Section id="01">
            <ImageSection>
              <Image src="./images/Capa_6.png" />
            </ImageSection>
            <ContentSection>
              <SectionTitle>END-TO-END-CONSULTANT</SectionTitle>
              <SectionDescent>Real Estate</SectionDescent>
              <Text justify>
                Through our one-shop approach, we provide our international and
                domestic clients with a wide range of real estate services from
                Property Scouting, Financing and Corporate Services to Property
                Management and Construction Administration, relieving our
                clients from the day-to-day hassles of owning real estate. The
                scope of our services include:
              </Text>
              <List>
                <li>
                  <Strong>Property Brokerage: </Strong>Based on the investor’s
                  profile and needs, we identify potential targets, provide
                  business case analysis, and deliver advice based on our
                  experience, knowledge of the area and the market.
                </li>
                <li>
                  <Strong>Structuring: </Strong>
                  We assist clients coordinate with our network of accountants
                  and lawyers who will advise on the optimal investment vehicle
                  for their particular needs.
                </li>
                <li>
                  <Strong>Management: </Strong>
                  For both international and domestic investors, we lease the
                  properties, manage daily maintenance issues and contact with
                  tenants, maintain book keeping and liaise with accountants.
                </li>
                <li>
                  <Strong>Financing: </Strong>
                  Through our network of both, private and bank financing
                  professionals, we can assist buyers find a tailored made
                  solution for their needs.
                </li>
              </List>
            </ContentSection>
          </Section>
          <Section id="02">
            <Hidden mdUp>
              <ImageSection>
                <Image right src="./images/Capa_0.png" />
              </ImageSection>
            </Hidden>
            <ContentSection>
              <SectionTitle>ON TIME, ON BUDGET</SectionTitle>
              <SectionDescent>Owner’s Representative Services</SectionDescent>
              <Text justify>
                We, as Owner's Representatives, work as an extension of the
                Owner, advising you and acting as a single point of contact for
                all parties involved in the project effectively freeing you to
                continue focusing on your core business, knowing that a
                dedicated and experienced representative protects your interests
                throughout the course of the project. You, as the owner
                maintain ALL decision-making authority and contractual
                relationships, while we interact with all project team members
                on your behalf -Architects, Engineers, General contractor, sub
                contractors, etc- tracking issues, maximizing efficiencies and
                facilitating and expediting solutions to avoid expensive change
                orders, unexpected costs and delays that are the main cause of
                friction, uneasiness and unnecessary stress usually related to
                the design and construction projects. With a background in
                architecture, construction and extensive experience in project
                management, our oversight will prevent costly uninformed
                decisions, errors, delays resulting in savings of time and
                money.
              </Text>
            </ContentSection>

            <Hidden smDown>
              <ImageSection>
                <Image right src="./images/Capa_0.png" />
              </ImageSection>
            </Hidden>
          </Section>
          <Section id="03">
            <ImageSection>
              <Image src="./images/Capa_5.png" />
            </ImageSection>
            <ContentSection>
              <SectionTitle>FORMATION NEEDS</SectionTitle>
              <SectionDescent>Corporate Services</SectionDescent>
              <Text justify>
                Whether you are buying property, or starting a new business in
                the US, we can help you with your corporate formation needs.
              </Text>
              <List>
                <li>Business Incorporation</li>
                <li>EIN Processing</li>
                <li>Corporate Maintenance</li>
                <li>Banking Introductions</li>
                <li>
                  Introduction and Coordination with legal counsel, accountants
                  and banks.
                </li>
              </List>
            </ContentSection>
          </Section>
          <Section id="04">
            <Hidden mdUp>
              <ImageSection>
                <Image right src="./images/Capa_4.png" />
              </ImageSection>
            </Hidden>
            <ContentSection>
              <SectionTitle>PERSONALIZED ADVICE</SectionTitle>
              <SectionDescent>Business Consulting</SectionDescent>
              <Text justify>
                Whether a family office, investor or entrepreneur we offer
                clients advise and back-office support to develop and analyze
                business value.
              </Text>
              <List>
                <li>Creation of Business Plans</li>
                <li>Investment Analysis</li>
                <li>Corporate Presentations</li>
                <li>Company Valuations</li>
              </List>
            </ContentSection>

            <Hidden smDown>
              <ImageSection>
                <Image right src="./images/Capa_4.png" />
              </ImageSection>
            </Hidden>
          </Section>
        </ContentWrapper>
      </Main>
      <Footer>
        <A href="mailto: info@hoxtonconsulting.com">
          info@hoxtonconsulting.com
        </A>
      </Footer>
    </Root>
  )
}

/* Styles */

const Root = styled.div`
  font-family: ${(props) => props.theme.fonts.primary.regular};
  width: 100%;
`
const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: stretch;
`

const ContentWrapper = styled.div`
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
  //overflow-x: hidden;
`

const Footer = styled.div`
  background: #3db8b1;
  width: 100%;
  text-align: center;
  padding: 20px 0px;
`

const Section = styled.div`
  box-sizing: border-box;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.surface ? '#e4edf2' : 'transparent')};

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    padding: 40px 20px;
    flex-direction: row;
  }
`

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    width: 45%;
    padding: 0px 20px;
  }
`

const Image = styled.img`
  width: 100%;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    width: 600px;
    /* margin-left: ${(props) => (props.right ? '0px' : '100px')};
    margin-right: ${(props) => (props.right ? '100px' : '0px')}; */
  }
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    width: 65%;
  }
`

const SectionTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.primary.regular};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #3db8b1;
  margin: 0px;
  ${(props) => props.theme.mui.breakpoints.up('md')} {
    font-size: 18px;
  }
`

const SectionDescent = styled.h2`
  /* H2 */
  font-family: ${(props) => props.theme.fonts.primary.regular};
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  color: #1666a1;
  margin: 20px 0px;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    font-size: 32px;
    line-height: 34px;
  }
`

const ContentImageHero = styled.div`
  box-sizing: border-box;
  width: 100%;
  background: url(${(props) => props.mobile});

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    background: url(${(props) => props.desktop});
    min-height: 490px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 10% 100%;
  }
`

const AboutSectionWrapper = styled.div`
  padding: 30px 0px;
  margin: auto;
  width: 80%;
  height: 100%;
  text-align: center;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    padding: 60px 30px;
    width: 50%;
    margin-right: 0px;
    text-align: left;
  }

  ${(props) => props.theme.mui.breakpoints.up('lg')} {
    width: 35%;
    margin-right: 50px;
  }

  ${(props) => props.theme.mui.breakpoints.up('xl')} {
    padding-top: 200px;
    width: 30%;
    margin-right: 300px;
  }
`

const Text = styled.p`
  text-align: ${(props) => (props.justify ? 'justify' : 'center')};
  line-height: 30px;
  color: #00426b;
  width: 80%;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    width: 85%;
  }
`

const P = styled.p`
  font-size: 24px;
  line-height: 34px;
  color: #fff;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    font-size: 26px;
    line-height: 38px;
  }
`

const Strong = styled.p`
  font-weight: 800;
  display: inline;
`

const List = styled.ul`
  text-align: left;
  line-height: 30px;
  color: #00426b;
  width: 85%;

  ${(props) => props.theme.mui.breakpoints.up('md')} {
    width: 80%;
    padding-left: 30px;
  }
`

const A = styled.a`
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`

export default App
